import { Link } from 'react-router-dom';
import { Link as EnturLink } from '@entur/typography';
import { getDocsUrl } from '../config';
import { ExternalIcon } from '@entur/icons';
import useWindowDimensions, { isSmall } from '../hooks/useWindowDimensions';

type DatahubDomain = Components.Schemas.DatahubDomain;

type HeaderNavigationProps = {
    domains: DatahubDomain[];
    useContrast: boolean;
};

const HeaderNavigation = ({ domains, useContrast }: HeaderNavigationProps) => {
    let docsUrl = getDocsUrl();
    const { width } = useWindowDimensions();
    const logoImageContrast = (
        <img src={'images/Enturlogo_White_RGB.svg'} alt={'Entur data logo'} className="entur-logo-image-contrast" />
    );
    const logoImage = <img src={'images/entur_colour.svg'} alt={'Entur data logo'} className="entur-logo-image" />;

    if (isSmall(width)) {
        return <HeaderNavigationSmall domains={domains} docsUrl={docsUrl} useContrast={useContrast} />;
    } else {
        return (
            <nav className={useContrast ? 'top-navigation' : 'top-navigation top-navigation-border'}>
                <Link to={'/'} aria-label="Entur data logo, redirects to home page" style={{ paddingLeft: '2rem' }}>
                    {useContrast ? logoImageContrast : logoImage}
                </Link>
                <EnturLink
                    href={docsUrl}
                    target="_blank"
                    className={'bold'}
                    style={{ position: 'absolute', right: '5rem', margin: '1rem' }}
                >
                    Help <ExternalIcon inline={true} />
                </EnturLink>
            </nav>
        );
    }
};

type HeaderNavigationPhoneProps = {
    domains: DatahubDomain[];
    docsUrl: string;
    useContrast: boolean;
};
const HeaderNavigationSmall = ({ domains, docsUrl, useContrast }: HeaderNavigationPhoneProps) => {
    const logoImageContrast = (
        <img src={'images/Enturlogo_White_RGB.svg'} alt={'Entur data logo'} className="entur-logo-image-contrast" />
    );
    const logoImage = <img src={'images/entur_colour.svg'} alt={'Entur data logo'} className="entur-logo-image" />;
    return (
        <>
            <div style={useContrast ? { padding: '0.25rem' } : { padding: '0.5rem', paddingTop: '1.5rem' }}>
                <Link to={'/'} aria-label="Entur data logo, redirects to home page">
                    {useContrast ? logoImageContrast : logoImage}
                </Link>
                <EnturLink
                    href={docsUrl}
                    target="_blank"
                    style={{ position: 'absolute', right: '2rem', top: '1.5rem', margin: '1rem' }}
                >
                    Help <ExternalIcon inline={true} />
                </EnturLink>
            </div>
        </>
    );
};

export default HeaderNavigation;
