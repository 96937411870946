import React from 'react';
import { GridContainer, GridItem } from '@entur/grid';
import { Contrast } from '@entur/layout';
import { COMMON_SIZE_SMALL } from '../App';
import { Heading3, Link as EnturLink, Paragraph } from '@entur/typography';

export type CommonDomainlessPageFooterProps = {
    isContrast: boolean;
};

export const CommonDomainlessPageFooter = ({ isContrast }: CommonDomainlessPageFooterProps) => {
    const logoImageContrast = (
        <img
            src={'images/Enturlogo_White_RGB.svg'}
            alt={'Entur data logo'}
            className="entur-logo-image-contrast"
            style={{ marginLeft: '-26px' }}
        />
    );
    const logoImageGray = (
        <img src={'images/entur_colour.svg'} alt={'Entur-logo'} className="entur-logo-image" style={{ margin: 0 }} />
    );
    const logoImage = isContrast ? logoImageContrast : logoImageGray;

    const content = (
        <div
            style={{ padding: '2rem 2rem 3rem 2rem', display: 'flex', justifyContent: 'center' }}
            className="footer-home__text"
        >
            <GridContainer style={{ maxWidth: '1024px' }} className={isContrast ? '' : 'gray-border-top'}>
                <GridItem small={COMMON_SIZE_SMALL.mainContent} large={6}>
                    <Heading3 as="h2">Entur AS</Heading3>
                    <Paragraph>
                        Rådhusgata 5, 0151 Oslo
                        <br />
                        Postboks 1554, 0117 Oslo
                    </Paragraph>
                    <Paragraph>
                        Organization number:
                        <br />
                        917 422 575
                    </Paragraph>
                    {logoImage}
                </GridItem>
                <GridItem small={COMMON_SIZE_SMALL.mainContent} large={6}>
                    <span style={{ float: 'none' }}>
                        <Heading3 as="h2">Information</Heading3>
                        <EnturLink
                            as={'a'}
                            href="https://uustatus.no/nb/erklaringer/publisert/9c46d0dc-f160-4380-806a-5dbfd9967095"
                        >
                            Accessibility statement
                        </EnturLink>
                        <br />
                        <br />
                        <Paragraph>We appreciate all feedback about the Entur Data Catalog.</Paragraph>
                        <EnturLink as={'a'} href="mailto:Analytics-guild@entur.org">
                            Analytics-guild@entur.org
                        </EnturLink>
                        <br />
                        <EnturLink as={'a'} href="https://forms.gle/j4UV5c2CDTBU2nXp9">
                            Give us feedback
                        </EnturLink>
                        <br />
                        <br />
                        <span style={{ marginRight: '0.5rem' }}>data.entur.no</span>
                        <span>© 2024 Entur AS</span>
                    </span>
                </GridItem>
            </GridContainer>
        </div>
    );

    if (isContrast) {
        return <Contrast>{content}</Contrast>;
    } else {
        return <div style={{ borderTop: '2px solid #f3f3f3' }}>{content}</div>;
    }
};

export default CommonDomainlessPageFooter;
