import { Loader } from '@entur/loader';
import { TabPanel } from '@entur/tab';
import { DataCell, HeaderCell, Table, TableBody, TableHead, TableRow } from '@entur/table';
import React from 'react';

type MetadataSchemaDetailProps = {
    schema?: Components.Schemas.TableSchema;
};

const SchemaTab = ({ schema }: MetadataSchemaDetailProps) => {
    if (!schema) {
        return (
            <TabPanel>
                <Loader data-testid="schema-loader" />
            </TabPanel>
        );
    }

    return (
        <TabPanel style={{ width: '100%', height: '40rem', overflowY: 'scroll', fontSize: '14px' }}>
            <Table stickyHeader spacing="small">
                <TableHead>
                    <TableRow>
                        <HeaderCell>Field Name</HeaderCell>
                        <HeaderCell>Type</HeaderCell>
                        <HeaderCell>Nullable</HeaderCell>
                        <HeaderCell>Description</HeaderCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {schema.fields?.map((field, row_index) => (
                        <TableRow key={row_index}>
                            <DataCell>
                                {field.fieldPath
                                    ?.split('.')
                                    .filter((s) => !(s.includes('[') || s.includes(']')))
                                    .reduce((a, b) => a + '.' + b)}
                            </DataCell>
                            <DataCell>{field.nativeDataType ? field.nativeDataType : field.type}</DataCell>
                            <DataCell>{field.nullable + ''}</DataCell>
                            <DataCell>{field.description}</DataCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TabPanel>
    );
};

export default SchemaTab;
