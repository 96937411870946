import React from 'react';
import {
    DataCell,
    ExpandableRow,
    ExpandRowButton,
    HeaderCell,
    Table,
    TableBody,
    TableHead,
    TableRow
} from '@entur/table';
import { TabPanel } from '@entur/tab';
import { useQuery } from 'react-query';
import { getPublicDataPreview } from '../../API';
import { Loader } from '@entur/loader';
import { DataCellType } from '../../ApiTypes';

type BQTablePreviewProps = {
    project: string;
    dataset: string;
    table: string;
    schema?: Components.Schemas.TableSchema;
};

function tablePreview(rows: DataCellType[][]) {
    return (
        <TableBody data-testid="preview-body">
            {rows.map((row, row_index) => (
                <TableRow key={row_index}>
                    {row.map((cell, cell_index) => {
                        if (cell.value) {
                            return <DataCell key={row_index + '' + cell_index}>{cell.value}</DataCell>;
                        } else if (cell.values && cell.values.length > 0) {
                            return (
                                <DataCell key={row_index + '' + cell_index}>
                                    <ExpRow nestedDataCells={cell.values} />
                                </DataCell>
                            );
                        } else {
                            return (
                                <DataCell key={row_index + '' + cell_index}>
                                    {cell.value === 'null' ? 'null' : ''}
                                </DataCell>
                            );
                        }
                    })}
                </TableRow>
            ))}
        </TableBody>
    );
}

const ExpRow = ({ nestedDataCells }: { nestedDataCells: DataCellType[] }): JSX.Element => {
    const [open, setOpen] = React.useState(true);

    function buildTableCellsOrNestedExpandableTables(nestedDataCells: DataCellType[]) {
        return nestedDataCells.map((nestedDataCell, cell_index) => {
            var cellBody: JSX.Element | String = !!nestedDataCell.value
                ? nestedDataCell.value
                : nestedDataCell.value === 'null'
                ? 'null'
                : '';

            if (nestedDataCell.values) {
                if (nestedDataCell.values.length > 1) {
                    return (
                        <DataCell key={'nested_' + cell_index}>
                            <ExpRow nestedDataCells={nestedDataCell.values} />
                        </DataCell>
                    );
                }
            }
            return <DataCell key={'cell_' + cell_index}>{cellBody}</DataCell>;
        });
    }

    return (
        <React.Fragment>
            <ExpandRowButton onClick={() => setOpen(!open)} open={open} />
            {/* main table for expansion */}
            <Table spacing="small">
                <TableBody>
                    <ExpandableRow colSpan={nestedDataCells.length} open={open}>
                        {/* wrap each level of expansion in its own table for a valid DOM-tree */}
                        <Table spacing="small">
                            <TableBody>
                                <TableRow style={{ borderBottom: 'none' }}>
                                    {buildTableCellsOrNestedExpandableTables(nestedDataCells)}
                                </TableRow>
                            </TableBody>
                        </Table>
                    </ExpandableRow>
                </TableBody>
            </Table>
        </React.Fragment>
    );
};

const BQDataPreviewTab = ({ project, dataset, table, schema }: BQTablePreviewProps) => {
    const tablePreviewQuery = useQuery(`table-preview-${project}-${dataset}-${table}`, () =>
        getPublicDataPreview(project, dataset, table)
    );

    if (tablePreviewQuery.isLoading) {
        return (
            <TabPanel>
                <Loader data-testid="preview-loader">Loading table preview...</Loader>
            </TabPanel>
        );
    } else if (tablePreviewQuery.isError) {
        return <TabPanel>Failed to load table preview.</TabPanel>;
    } else {
        return (
            <TabPanel style={{ width: '100%', height: '40rem', overflowY: 'scroll', fontSize: '14px' }}>
                <Table stickyHeader spacing="small">
                    {schema ? (
                        <TableHead data-testid="preview-header">
                            <TableRow>
                                {schema.fields
                                    ?.map((field) => {
                                        const nonNestedFieldPath = field.fieldPath
                                            ?.split('.')
                                            .filter((s) => !(s.includes('[') || s.includes(']')));
                                        if (nonNestedFieldPath?.length === 1) {
                                            return nonNestedFieldPath;
                                        } else {
                                            return null;
                                        }
                                    })
                                    .filter((nullable) => nullable != null)
                                    .map((fieldPath, row_index) => (
                                        <HeaderCell key={row_index}>{fieldPath}</HeaderCell>
                                    ))}
                            </TableRow>
                        </TableHead>
                    ) : null}
                    {tablePreviewQuery.data?.rows ? tablePreview(tablePreviewQuery.data.rows) : null}
                </Table>
            </TabPanel>
        );
    }
};

export default BQDataPreviewTab;
