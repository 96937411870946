import { TabPanel } from '@entur/tab';
import React, { useState } from 'react';
import { Loader } from '@entur/loader';
import { Paragraph } from '@entur/typography';

type DocumentationTabProps = {
    entityHtmlDescription: string;
};
const DocumentationTab = ({ entityHtmlDescription }: DocumentationTabProps) => {
    const showdown = require('showdown');
    showdown.setFlavor('github');
    const defaultConvOptions = { tables: true, strikethrough: true, emoji: true, openLinksInNewWindow: true };
    const converter = new showdown.Converter(defaultConvOptions);
    const [parsedHtml, setParsedHtml] = useState<string>('');

    const compileMarkdownToHtml = async (markdown: string): Promise<string> => {
        return converter.makeHtml(
            markdown // fix strikethrough, only single "tilde" (i.e. '~') in Datahub markdown-language.
                .replaceAll('~', '~~')
                .replaceAll('~~~', '~~')
        );
    };
    compileMarkdownToHtml(entityHtmlDescription).then((html) => setParsedHtml(html));

    if (entityHtmlDescription == null || entityHtmlDescription === '') {
        return (
            <TabPanel>
                <Paragraph style={{ padding: '0.5rem', paddingLeft: '0', marginTop: '0.5rem' }}>
                    No documentation was found for this data asset.
                </Paragraph>
            </TabPanel>
        );
    } else if (parsedHtml == null || parsedHtml === '') {
        return (
            <TabPanel>
                <Loader />
            </TabPanel>
        );
    }
    return (
        <TabPanel>
            <div dangerouslySetInnerHTML={{ __html: parsedHtml }} className={'data-asset-documentation'} />
        </TabPanel>
    );
};

export default DocumentationTab;
