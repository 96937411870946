import React from 'react';
import { Link as EnturLink, Paragraph } from '@entur/typography';
import { TabPanel } from '@entur/tab';

const NSRTab = () => {
    return (
        <TabPanel>
            <Paragraph style={{ padding: '0.5rem', paddingLeft: '0', marginTop: '0.5rem' }}>
                You may download the latest Stop Places Dataset here:{' '}
                <EnturLink
                    as="a"
                    href="https://storage.googleapis.com/marduk-production/tiamat/Current_latest-gtfs.zip"
                >
                    Current_latest-gtfs.zip
                </EnturLink>
            </Paragraph>
        </TabPanel>
    );
};

export default NSRTab;
