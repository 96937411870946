import { getApiUrl } from './config';
import { DataPreview, Product } from './ApiTypes';

type DatahubContainer = Components.Schemas.DatahubContainer;
type DatahubDomain = Components.Schemas.DatahubDomain;
type DatahubDataset = Components.Schemas.DatahubDataset;
type TimetableFiles = Components.Schemas.TimetableFiles;

require('isomorphic-fetch');

const API_URL = getApiUrl();
const API_VERSION = '/v1';

function standardHeaders() {
    return {
        'Content-Type': 'application/json'
    };
}

async function doRequest<T>(
    path: string,
    method: string = 'GET',
    parameters: string = '',
    expectedResponse = 200
): Promise<T> {
    return fetch(`${API_URL}${path}${parameters}`, {
        credentials: 'include',
        method: method,
        headers: standardHeaders()
    }).then((response) => {
        if (response.status === expectedResponse) {
            return response.json();
        } else {
            return Promise.reject(`${response.status.toString()} received from backend`);
        }
    });
}

export const getDomains = async () => {
    return doRequest<DatahubDomain[]>(`${API_VERSION}/datahub/domains`);
};

export const getContainersForDomain = async (domainUrn: string): Promise<DatahubContainer[]> => {
    return doRequest<Product[]>(`${API_VERSION}/datahub/domain/${domainUrn}`);
};

export const getAssetsForContainer = async (datasetUrn: string): Promise<DatahubDataset[]> => {
    return doRequest<DatahubDataset[]>(`${API_VERSION}/datahub/container/${datasetUrn}`);
};

export const getPublicDataPreview = async (project: string, dataset: string, table: string): Promise<DataPreview> => {
    return doRequest<DataPreview>(`${API_VERSION}/bigquery/${project}/${dataset}/${table}`);
};

export async function getVersionedTimetableNeTExFiles(
    pageSize: number = 100,
    pageToken: string = ''
): Promise<TimetableFiles> {
    return doRequest<TimetableFiles>(
        `${API_VERSION}/get-timetable-netex-files?pageSize=${pageSize}&pageToken=${pageToken}`
    );
}

export async function getVersionedTimetableGTFSFiles(
    pageSize: number = 100,
    pageToken: string = ''
): Promise<TimetableFiles> {
    return doRequest<TimetableFiles>(
        `${API_VERSION}/get-timetable-gtfs-files?pageSize=${pageSize}&pageToken=${pageToken}`
    );
}
