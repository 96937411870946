import React, { useState } from 'react';
import { TabPanel } from '@entur/tab';
import { useQuery } from 'react-query';
import { getVersionedTimetableNeTExFiles } from '../../API';
import { Loader } from '@entur/loader';
import TimetableFilesChart from './TimetableFilesChart';

type TimetableFile = Components.Schemas.TimetableFile;
type TimetableFiles = Components.Schemas.TimetableFiles;

const NeTExFilesTab = () => {
    const [nextPageToken, setNextPageToken] = useState('');
    const [files, setFiles] = useState<TimetableFile[]>([]);
    const [pageTokens, setPageTokens] = useState<string[]>([nextPageToken]);

    const timetableFilesQuery = useQuery(`get-netex-files-${nextPageToken}`, () =>
        getVersionedTimetableNeTExFiles(365, nextPageToken).then((timetableFiles: TimetableFiles) => {
            if (timetableFiles.nextPageToken != null && !pageTokens.includes(timetableFiles.nextPageToken)) {
                setPageTokens(pageTokens.concat(timetableFiles.nextPageToken));
                setFiles(files.concat(timetableFiles.files));
            }
            return timetableFiles;
        })
    );

    if (timetableFilesQuery.data) {
        return (
            <TabPanel>
                <TimetableFilesChart
                    files={files}
                    setNextPageToken={() => {
                        const nextPagetoken = timetableFilesQuery.data.nextPageToken;
                        if (nextPagetoken != null && nextPagetoken !== 'none' && nextPagetoken !== '') {
                            setNextPageToken(nextPagetoken);
                        }
                    }}
                />
            </TabPanel>
        );
    } else if (timetableFilesQuery.isLoading) {
        return (
            <TabPanel>
                <Loader />
            </TabPanel>
        );
    } else {
        return (
            <TabPanel>
                Could not load NeTEx files.. We apologise for any inconvenience this may have caused. Please try again
                shortly.
            </TabPanel>
        );
    }
};

export default NeTExFilesTab;
