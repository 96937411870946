import React from 'react';
import { GridContainer, GridItem } from '@entur/grid';
import { Heading1, Heading2 } from '@entur/typography';
import { Contrast } from '@entur/layout';
import HeaderNavigation from './HeaderNavigation';
import { colors } from '@entur/tokens';
import useWindowDimensions from '../hooks/useWindowDimensions';

const CommonDomainlessPageHeader = () => {
    const { width } = useWindowDimensions();
    const paddingSmall = width < 1140 ? '2rem' : '0';

    return (
        <Contrast>
            <HeaderNavigation domains={[]} useContrast={true} />
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <GridContainer style={{ maxWidth: '1024px', paddingLeft: paddingSmall }}>
                    <GridItem small={12}>
                        <div style={{ padding: '3rem 1rem 3rem 1rem' }}>
                            <Heading1 style={{ margin: '0', color: 'white' }}>Entur Data Catalog</Heading1>
                            <Heading2 style={{ color: colors.brand.coral, margin: '0' }}>
                                shared public transport data
                            </Heading2>
                        </div>
                    </GridItem>
                    <GridItem small={12} style={{ height: '3rem' }} />
                </GridContainer>
            </div>
        </Contrast>
    );
};

export default CommonDomainlessPageHeader;
