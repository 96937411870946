import { Heading3, Link as EnturLink, Paragraph } from '@entur/typography';
import React from 'react';
import { getDQReportUrl } from '../config';
import { ExternalIcon } from '@entur/icons';

const HighlightedCardDQReport = () => {
    return (
        <EnturLink as={'a'} target={'_blank'} href={getDQReportUrl()} className="data-card-container">
            <div className="data-product-card" style={{ maxWidth: '1024px' }}>
                <ExternalIcon inline={true} size={'20px'} style={{ float: 'right' }} />
                <div className="highlighted-card">
                    <img
                        src={'images/dq-report.png'}
                        alt={'An illustration from the data-quality report'}
                        style={{
                            maxWidth: '360px',
                            width: '20vw',
                            height: 'auto',
                            padding: '1rem',
                            marginRight: '4rem'
                        }}
                    />
                    <div>
                        <Heading3>Data Quality Dashboard - Real-Time Data</Heading3>
                        <Paragraph>
                            This report provides data quality metrics for real-time estimated timetable (SIRI-ET) data
                            across all suppliers. Its purpose is to provide a tool for data quality evaluation, such
                            that data suppliers may pin-point the exact source of data quality issues, track development
                            over time, compare operator-level issues, and share knowledge.
                        </Paragraph>
                    </div>
                </div>
            </div>
        </EnturLink>
    );
};

export default HighlightedCardDQReport;
