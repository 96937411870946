import { useEffect, useState } from 'react';
import { getDomains } from '../API';
type DatahubDomain = Components.Schemas.DatahubDomain;

const useDomains = () => {
    const [domains, setDomains] = useState<DatahubDomain[]>([]);
    const [error, setError] = useState<Error | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isSuccess, setIsSuccess] = useState(false);

    const fetchData = async () => {
        try {
            const data = await getDomains();
            setDomains(data);
            setError(null);
            setIsLoading(false);
            setIsSuccess(true);
        } catch (err) {
            if (err instanceof Error) {
                setError(err);
            } else {
                setError(new Error(String(err)));
            }
            setIsLoading(false);
            setIsSuccess(false);
        }
    };
    useEffect(() => {
        fetchData();
    }, []);

    return { domains, error, isLoading, isSuccess };
};

export default useDomains;
