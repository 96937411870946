import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@entur/tab';
import SchemaTab from './tabs/SchemaTab';
import SummaryTab from './tabs/SummaryTab';
import DocumentationTab from './tabs/DocumentationTab';
import BQDataPreviewTab from './tabs/BQDataPreviewTab';
import React from 'react';
import NeTExFilesTab from './tabs/NeTExFilesTab';
import GTFSFilesTab from './tabs/GTFSFilesTab';
import TimetableFilesDocumentationTab from './tabs/TimetableFilesDocumentationTab';
import NSRTab from './tabs/NSRTab';

type DatahubDataset = Components.Schemas.DatahubDataset;

type DatasetTabsProps = {
    selectedDataset: DatahubDataset;
};

const DatahubDatasetTabs = ({ selectedDataset }: DatasetTabsProps) => {
    const projectName = selectedDataset.qualifiedName?.split('.')[0] || '';
    const datasetName = selectedDataset.qualifiedName?.split('.')[1] || '';
    const tableName = selectedDataset.qualifiedName?.split('.')[2] || '';

    function emptyTabPanel() {
        return <TabPanel>''</TabPanel>;
    }

    const datasetIsTimetables = selectedDataset.documentation === '_timetable_files';
    const datasetIsNSR = selectedDataset.name === 'stop_places_last_version';
    const isDownloadable = datasetIsTimetables || datasetIsNSR;

    const downloadableContentTab = () => {
        return isDownloadable ? (
            datasetIsTimetables ? (
                selectedDataset.urn.includes('netex') ? (
                    <NeTExFilesTab />
                ) : (
                    <GTFSFilesTab />
                )
            ) : (
                <NSRTab />
            )
        ) : (
            emptyTabPanel()
        );
    };

    function previewTab() {
        return selectedDataset.previewAvailable ? (
            <BQDataPreviewTab
                project={projectName}
                dataset={datasetName}
                table={tableName}
                schema={selectedDataset.schema}
            />
        ) : (
            emptyTabPanel()
        );
    }

    function documentationTab() {
        return datasetIsTimetables ? (
            <TimetableFilesDocumentationTab />
        ) : (
            <DocumentationTab entityHtmlDescription={selectedDataset.documentation || ''} />
        );
    }

    return (
        <Tabs>
            <TabList>
                <Tab disabled={datasetIsTimetables}>About</Tab>
                <Tab disabled={!selectedDataset.schema}>Schema</Tab>
                <Tab disabled={!selectedDataset.previewAvailable}>Preview</Tab>
                <Tab disabled={!isDownloadable}>Download</Tab>
                <Tab className={'documentation-tab'}>Documentation</Tab>
            </TabList>
            <TabPanels>
                <SummaryTab datahubDataset={selectedDataset} />
                <SchemaTab schema={selectedDataset.schema} />
                {previewTab()}
                {downloadableContentTab()}
                {documentationTab()}
            </TabPanels>
        </Tabs>
    );
};

export default DatahubDatasetTabs;
