import { BannerAlertBox } from '@entur/alert';
import { Loader } from '@entur/loader';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getAssetsForContainer } from '../API';
import ProductDetailsContent from './ProductDetailsContent';
import { findDomain } from '../hooks/domainUtils';
import HeaderNavigation from '../components/HeaderNavigation';
import { CommonDomainlessPageFooter } from '../components/CommonDomainlessPageFooter';

type DatahubDomain = Components.Schemas.DatahubDomain;

export type ProductDetailsProps = {
    domains: DatahubDomain[];
};

const ProductDetailsPage = ({ domains }: ProductDetailsProps) => {
    const { domainPathName, productUrn, containerName } = useParams();
    const containerDatasetsQuery = useQuery(['get-container-datasets', productUrn], () =>
        getAssetsForContainer(productUrn || '')
    );

    if (containerDatasetsQuery.isLoading) {
        return <Loader>Loading tables..</Loader>;
    }

    if (containerDatasetsQuery.isError) {
        const error = containerDatasetsQuery.error || 'An unknown error occurred';
        return <BannerAlertBox variant={'error'}>Failed to load tables: {error}</BannerAlertBox>;
    }

    if (!containerDatasetsQuery.isSuccess || !containerDatasetsQuery.data) {
        return (
            <BannerAlertBox variant={'warning'}>
                Failed to load the tables. We apologise for any inconvenience this may have caused. Please try again
                shortly.
            </BannerAlertBox>
        );
    }

    if (containerDatasetsQuery.data.length === 0) {
        return (
            <>
                <HeaderNavigation domains={domains} useContrast={false} />
                <div style={{ padding: '2rem' }}>No public tables found for the current data product.</div>
            </>
        );
    }

    const currentDomain = findDomain(domains, domainPathName);
    return (
        <>
            <HeaderNavigation domains={domains} useContrast={false} />
            <ProductDetailsContent
                domainName={currentDomain?.name || ''}
                containerName={containerName || ''}
                productUrn={productUrn || ''}
                datasets={containerDatasetsQuery.data}
            />
            <CommonDomainlessPageFooter isContrast={false} />
        </>
    );
};

export { ProductDetailsPage };
