import { GridContainer, GridItem } from '@entur/grid';
import { ForwardIcon } from '@entur/icons';
import { Heading3, Link as EnturLink } from '@entur/typography';
import { Link } from 'react-router-dom';
import { DOMAIN_PATH, PRODUCT_PATH } from '../App';
import { domainNameToPath } from '../hooks/domainUtils';
import useTruncate from '../hooks/useTruncate';
import useWindowDimensions, { isSmall } from '../hooks/useWindowDimensions';

type DatahubDomain = Components.Schemas.DatahubDomain;
type DatahubContainer = Components.Schemas.DatahubContainer;

type ProductCardProps = {
    product: DatahubContainer;
    domain: DatahubDomain;
    maxChars: number;
};

const DataProductCard = ({ product, domain, maxChars }: ProductCardProps) => {
    const { width } = useWindowDimensions();
    const truncatedDescription = useTruncate(
        product.description || product.editableDescription || 'Description missing',
        maxChars
    );
    const detailsLinkClassName = isSmall(width)
        ? 'product-card-details-link-small'
        : 'product-card-details-link-medium-large';

    return (
        <EnturLink
            as={Link}
            to={`/${DOMAIN_PATH}/${domainNameToPath(domain.name)}/${PRODUCT_PATH}/${product.name}/${product.urn}`}
            className="data-card-container"
        >
            <div className="data-product-card">
                <div>
                    <Heading3 style={{ display: 'inline' }}>{product.displayName || product.name}</Heading3>
                    <EnturLink className={detailsLinkClassName} as={'span'}>
                        Details <ForwardIcon inline={true} width={12} />
                    </EnturLink>
                </div>

                <hr />
                <div style={{ padding: '1rem 0' }}>{truncatedDescription}</div>
                <GridContainer spacing="medium" style={{ padding: '1rem 0 0 0' }}>
                    <GridItem small={5}>
                        <span className="bold">Source:</span> {product.source}
                    </GridItem>
                    <GridItem small={5}>
                        <span className="bold">Storage:</span> {product.platformName}
                    </GridItem>
                    <GridItem small={2}>
                        <span className="bold">Access:</span> {product.isOpen ? 'Open' : 'Closed'}
                    </GridItem>
                </GridContainer>
            </div>
        </EnturLink>
    );
};

export default DataProductCard;
