import { Heading3, Link as EnturLink, Paragraph } from '@entur/typography';
import React from 'react';
import { ExternalIcon } from '@entur/icons';

const HighlightedCardEstimatePrecisionReport = () => {
    return (
        <EnturLink
            as={'a'}
            target={'_blank'}
            href="https://lookerstudio.google.com/reporting/1d6d59e5-05a2-42ee-b965-a1d390f323e8"
            className="data-card-container"
        >
            <div className="data-product-card" style={{ maxWidth: '1024px' }}>
                <ExternalIcon inline={true} size={'20px'} style={{ float: 'right' }} />
                <div className="highlighted-card">
                    <img
                        src={'images/estimate-precision.png'}
                        alt={'An illustration of estimate precision from Looker studio'}
                        style={{
                            maxWidth: '360px',
                            width: '22vw',
                            height: 'auto',
                            padding: '3rem 2rem',
                            marginRight: '4rem'
                        }}
                    />
                    <div>
                        <Heading3>Estimate Precision - Real-Time Data</Heading3>
                        <Paragraph>
                            This report provides a way to measure the quality of real time arrival time information from
                            a user's perspective. It contains an overview of all the data suppliers in Norway, as well
                            as a breakdown of monthly aggregates per operator, in addition to a time series. These
                            contain the key performance metrics communly used to measure estimate precision, MAE and
                            MSE.
                        </Paragraph>
                    </div>
                </div>
            </div>
        </EnturLink>
    );
};

export default HighlightedCardEstimatePrecisionReport;
