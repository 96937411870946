import React from 'react';
import { GridContainer, GridItem } from '@entur/grid';
import { Heading3, Paragraph } from '@entur/typography';
import { DataCell, Table, TableBody, TableRow } from '@entur/table';
import { PrimaryButton, SecondaryButton } from '@entur/button';
import { ExternalIcon } from '@entur/icons';
import { TabPanel } from '@entur/tab';

type SummaryTabProps = {
    datahubDataset: Components.Schemas.DatahubDataset;
};

const SummaryTab = ({ datahubDataset }: SummaryTabProps) => {
    function tableRowIfNotNull(cellName: string, cellValue?: string) {
        if (cellValue != null) {
            return (
                <TableRow>
                    <DataCell>{cellName}</DataCell>
                    <DataCell>{cellValue}</DataCell>
                </TableRow>
            );
        } else {
            return undefined;
        }
    }

    function parseDateStringFromTimestamp(lastUpdated?: string) {
        if (lastUpdated !== undefined) {
            return new Intl.DateTimeFormat('en-GB').format(new Date(Number.parseInt(lastUpdated)));
        }
        return undefined;
    }

    function formatBytesString(bytes?: number): string | undefined {
        const decimals = 2;

        if (!bytes) return undefined;
        if (bytes === 0) return '0 Bytes';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    function formatNumberString(number?: number) {
        return number ? new Intl.NumberFormat('no-NO').format(BigInt(number)) : undefined;
    }

    function isView(table: Components.Schemas.DatahubDataset) {
        return table.typeName?.toUpperCase() === 'VIEW';
    }

    return (
        <TabPanel>
            <GridContainer>
                {datahubDataset.description && datahubDataset.description !== '""' ? (
                    <GridItem small={12}>
                        <Heading3>Description</Heading3>
                        <Paragraph>{withoutOuterQuotes(datahubDataset.description)}</Paragraph>
                    </GridItem>
                ) : (
                    ''
                )}
                <GridItem small={12} className="content-border-top">
                    <Heading3 style={{ marginTop: '1rem' }}>Summary</Heading3>
                    <Table>
                        <TableBody>
                            {tableRowIfNotNull('Platform', datahubDataset.platform?.displayName)}
                            {tableRowIfNotNull('Type', datahubDataset.typeName)}
                            {tableRowIfNotNull('Rows', formatNumberString(datahubDataset.numRows))}
                            {tableRowIfNotNull('Size', formatBytesString(datahubDataset.size))}
                            {!isView(datahubDataset)
                                ? tableRowIfNotNull(
                                      'Last Updated',
                                      parseDateStringFromTimestamp(datahubDataset.lastUpdated)
                                  )
                                : ''}
                            {tableRowIfNotNull('Table Identifier (name)', datahubDataset.name)}
                        </TableBody>
                    </Table>
                </GridItem>
                {datahubDataset.platformName?.includes('BigQuery') ? (
                    <GridItem small={12} className="content-border-top" style={{ marginTop: '1rem' }}>
                        <Heading3>Access</Heading3>
                        <div style={{ marginBottom: '1rem' }}>For accessing or exploring this data asset.</div>
                        <PrimaryButton
                            as={'a'}
                            href={datahubDataset.externalUrl}
                            target="_blank"
                            style={{
                                marginRight: '0.5rem',
                                marginBottom: '0.5rem',
                                padding: '0.75rem 1rem'
                            }}
                        >
                            Open in BigQuery
                            <ExternalIcon />
                        </PrimaryButton>
                        {datahubDataset.exampleQueries ? (
                            <SecondaryButton
                                as={'a'}
                                target="_blank"
                                href={datahubDataset.exampleQueries}
                                style={{ padding: '0.75rem 1rem' }}
                            >
                                Example Queries
                                <ExternalIcon />
                            </SecondaryButton>
                        ) : (
                            ''
                        )}
                    </GridItem>
                ) : (
                    ''
                )}
            </GridContainer>
        </TabPanel>
    );
};

function withoutOuterQuotes(someStr: string): string {
    var strippedStr = someStr;
    const quoteChar = '"';
    if (strippedStr[0] === quoteChar) {
        strippedStr = strippedStr.substring(1);
    }
    if (strippedStr[strippedStr.length - 1] === quoteChar) {
        strippedStr = strippedStr.substring(0, strippedStr.length - 1);
    }

    return strippedStr;
}

export default SummaryTab;
