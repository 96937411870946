import { Link as EnturLink } from '@entur/typography';
import { ExternalIcon } from '@entur/icons';
import { getDocsUrl } from '../config';

type CustomDialogPageProps = {
    title: string;
    content: string;
};

const CustomDialogPage = ({ title, content }: CustomDialogPageProps) => {
    const logoImage = <img src={'images/entur_colour.svg'} alt={'Entur data logo'} className="entur-logo-image" />;
    let docsUrl = getDocsUrl();
    const headerWithoutDomains = (
        <nav className={'top-navigation top-navigation-border'}>
            <a href={'/'} aria-label="Entur data logo, redirects to home page" className={'header-logo'}>
                {logoImage}
                <b className={'header-logo-text'}>Data</b>
            </a>
            <EnturLink href={docsUrl} target="_blank" style={{ position: 'absolute', right: '2rem' }}>
                Help <ExternalIcon inline={true} />
            </EnturLink>
        </nav>
    );

    return (
        <>
            {headerWithoutDomains}
            <div style={{ padding: '2rem', display: 'flex', justifyContent: 'center' }}>
                <div style={{ maxWidth: '1024px' }}>
                    <h1>{title}</h1>
                    <p>{content}</p>
                </div>
            </div>
        </>
    );
};

export default CustomDialogPage;
