import { TabPanel } from '@entur/tab';
import React from 'react';
import { Paragraph } from '@entur/typography';
import { BannerAlertBox } from '@entur/alert';

const TimetableFilesDocumentationTab = () => {
    return (
        <TabPanel>
            <div>
                <Paragraph style={{ padding: '0.5rem' }}>
                    Part of our mission statement is to share our data with anyone who wants it, for free. This page
                    lists our timetables, and stop place data dumps which are updated daily. All data dumps have
                    permanent URL's and come as NeTEx or GTFS datasets. NeTEx is the official format for public
                    transport data in Norway and is the most complete in terms of available data. GTFS is a downstream
                    format with only a limited subset of the total data, but we generate datasets for it anyway since
                    GTFS can be easier to use and has a wider distribution among international public transport
                    solutions. GTFS sets come in "extended" and "basic" versions.
                </Paragraph>
                <BannerAlertBox title="" variant="info">
                    Please be aware that certain information, important to journey planning, may be lacking in the GTFS
                    files. This is, in particular, true for text-based information, which is not supported by GTFS.
                </BannerAlertBox>
            </div>
        </TabPanel>
    );
};

export default TimetableFilesDocumentationTab;
