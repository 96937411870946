import { Loader } from '@entur/loader';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Navigate } from 'react-router';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';

import useDomains from './hooks/useDomains';
import { ProductDetailsPage } from './pages/ProductDetailsPage';
import DataProductsPage from './pages/DataProductsPage';
import CustomDialogPage from './pages/CustomDialogPage';
import React from 'react';

const queryClient = new QueryClient();

export const DOMAIN_PATH = 'domain';
export const PRODUCT_PATH = 'product';
type sizeFieldType = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | undefined;
type gridSizeType = {
    padding: sizeFieldType;
    mainContent: sizeFieldType;
};
export const COMMON_SIZE_LARGE: gridSizeType = {
    padding: 1,
    mainContent: 10
};
export const COMMON_SIZE_SMALL: gridSizeType = {
    padding: 1,
    mainContent: 10
};

function App() {
    const { domains, error, isLoading, isSuccess } = useDomains();

    if (isLoading) {
        return <Loader style={{ paddingTop: '1.5rem' }}>Loading domains...</Loader>;
    } else if ((!isLoading && !isSuccess) || error) {
        return (
            <CustomDialogPage
                title="Failed to fetch data"
                content="It seems we could not fetch data from Datahub via our servers. We apologise for any inconvenience this may have caused, and kindly ask you to try again shortly."
            />
        );
    } else if (!isLoading && domains.length === 0) {
        return (
            <CustomDialogPage
                title="No domains found"
                content="No domains were found when querying the Datahub backend. Please try again shortly."
            />
        );
    }

    return (
        <QueryClientProvider client={queryClient}>
            <div className="App">
                <Router>
                    <Routes>
                        <Route
                            path={`/${DOMAIN_PATH}/:domainPathName`}
                            element={<DataProductsPage domains={domains} />}
                        />
                        <Route
                            path={`/${DOMAIN_PATH}/:domainPathName/${PRODUCT_PATH}/:containerName/:productUrn`}
                            element={<ProductDetailsPage domains={domains} />}
                        />
                        <Route
                            path="*"
                            element={<Navigate to={`/${DOMAIN_PATH}/public-transport-data`} replace={true} />}
                        />
                    </Routes>
                </Router>
            </div>
        </QueryClientProvider>
    );
}

export default App;
