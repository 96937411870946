import { Dropdown } from '@entur/dropdown';
import { GridContainer } from '@entur/grid';
import { SideNavigation, SideNavigationItem } from '@entur/menu';
import { Heading1, Heading2, Heading4 } from '@entur/typography';
import { useState } from 'react';
import DatahubDatasetsTabs from '../components/DatahubDatasetTabs';
import useWindowDimensions, { isSmall } from '../hooks/useWindowDimensions';

type DatahubDataset = Components.Schemas.DatahubDataset;
type ProductDetailsContentProps = {
    domainName: string;
    containerName: string;
    productUrn: string;
    datasets: DatahubDataset[];
};

const ProductDetailsContent = ({ datasets, containerName, productUrn, domainName }: ProductDetailsContentProps) => {
    const [selectedTable, setSelectedTable] = useState<DatahubDataset>(datasets[0]); // tables cannot be empty
    const handleTableClick = (table: DatahubDataset) => {
        setSelectedTable(table);
    };
    const { width } = useWindowDimensions();
    if (isSmall(width)) {
        return (
            <ProductDetailsContentSmall
                tables={datasets}
                productName={containerName}
                selectedTable={selectedTable}
                setSelectedTable={setSelectedTable}
            />
        );
    }

    const renderSideNavigationItem = (table: DatahubDataset) => (
        <SideNavigationItem
            key={table.urn}
            active={selectedTable.urn === table.urn}
            onClick={() => handleTableClick(table)}
            style={{ width: '100%', minWidth: '18rem', maxWidth: '26rem' }}
        >
            {table.displayName || table.name}
        </SideNavigationItem>
    );

    return (
        <GridContainer style={{ minHeight: '81vh', height: 'auto' }}>
            <div>
                <SideNavigation style={{ height: '100%' }}>
                    {/*custom side nav header styling*/}
                    <Heading4
                        as={Heading2}
                        style={{
                            margin: '0',
                            padding: '2.5rem',
                            borderBottom: '0.125rem solid #e9e9e9',
                            overflow: 'auto'
                        }}
                    >
                        Assets in <i>{containerName}</i>
                    </Heading4>
                    {datasets.map(renderSideNavigationItem)}
                </SideNavigation>
            </div>
            <div style={{ padding: '2rem 0', paddingLeft: '3rem', width: '1024px' }}>
                <Heading1>{selectedTable.displayName || selectedTable.name}</Heading1>
                <DatahubDatasetsTabs selectedDataset={selectedTable} />
            </div>
        </GridContainer>
    );
};

type ProductDetailsContentSmallProps = {
    productName: string;
    tables: DatahubDataset[];
    selectedTable: DatahubDataset;
    setSelectedTable: (table: DatahubDataset) => void;
};
const ProductDetailsContentSmall = ({
    tables,
    productName,
    selectedTable,
    setSelectedTable
}: ProductDetailsContentSmallProps) => {
    const handleChangeSelectedTable = (selectedTableName: string) => {
        const newSelectedTable = tables.find((it) => (it.displayName || it.name) === selectedTableName)!!;
        setSelectedTable(newSelectedTable);
    };

    return (
        <div style={{ minHeight: '85vh' }}>
            <Heading2 as={Heading2} style={{ padding: '1rem', margin: '0' }}>
                Assets in <i>{productName}</i>
            </Heading2>
            <div style={{ margin: '1rem', marginTop: '0' }}>
                <Dropdown
                    value={(selectedTable.displayName || selectedTable.name)!!}
                    items={tables.map((table) => (table.displayName || table.name)!!)}
                    onChange={(newSelectedTable) => handleChangeSelectedTable(newSelectedTable?.label!!)}
                    label={'Table'}
                />
            </div>
            <DatahubDatasetsTabs selectedDataset={selectedTable} />
        </div>
    );
};

export default ProductDetailsContent;
