type DatahubDomain = Components.Schemas.DatahubDomain;

export function domainNameToPath(domainName?: string | null): string {
    if (!domainName) {
        return '';
    }

    const transformedName = domainName
        .trim()
        .toLowerCase()
        .replace(/ /g, '-') // Replace spaces with hyphens
        .replace(/[^a-z0-9-]/g, ''); // Remove any character that isn't a lowercase letter, digit, or hyphen
    return `${transformedName}`;
}

export function findDomain(domains: DatahubDomain[], domainPathName?: string): DatahubDomain | null {
    const domain = domains.find((entry) => domainNameToPath(entry.name) === domainPathName);
    if (domain == null || domainPathName == null) {
        return null;
    } else {
        return domain;
    }
}
