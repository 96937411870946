import { DataCell, HeaderCell, Table, TableBody, TableHead, TableRow } from '@entur/table';
import { Link } from '@entur/typography';
import React from 'react';
import { Pagination } from '@entur/menu';

type TimetableFile = Components.Schemas.TimetableFile;

type TimetableFilesChartProps = {
    files: TimetableFile[];
    setNextPageToken: () => void;
};

const TimetableFilesChart = ({ files, setNextPageToken }: TimetableFilesChartProps) => {
    const [currentPage, setPage] = React.useState(1);
    const [results, setResults] = React.useState(10);
    const numberOfResults = files.length;
    const pageCount = Math.ceil(numberOfResults / results);

    return (
        <>
            <Pagination
                pageCount={pageCount}
                currentPage={currentPage}
                onPageChange={(page) => {
                    if (page === pageCount) {
                        setNextPageToken();
                    }
                    setPage(page);
                }}
                resultsPerPage={results}
                numberOfResults={numberOfResults}
                onResultsPerPageChange={(e) => {
                    setPage(1);
                    setResults(e);
                }}
                showNumberOfResultsLabel={'Display'}
                showingResultsLabel={(minPage: number, maxPage: number, pageCount: number) =>
                    `Showing results ${minPage}-${maxPage} of ${pageCount}`
                }
            />
            <Table style={{ marginTop: '1rem' }}>
                <TableHead>
                    <TableRow>
                        <HeaderCell>Date</HeaderCell>
                        <HeaderCell>Size</HeaderCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Array.isArray(files)
                        ? files
                              .slice(results * (currentPage - 1), results * currentPage)
                              .map((timetableFile, f_i) => TimetableFileTableRow(timetableFile, f_i))
                        : null}
                </TableBody>
            </Table>
        </>
    );
};

const TimetableFileTableRow = (file: TimetableFile, key: number) => {
    return (
        <TableRow key={key}>
            <DataCell>
                <Link className="download-link" as={'a'} href={file.url} role="anchor" download>
                    {file.date}
                </Link>
            </DataCell>
            <DataCell>{file.sizeInMegabytes}</DataCell>
        </TableRow>
    );
};

export default TimetableFilesChart;
